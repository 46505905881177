<template>
  <div class="container">
    <alert
      v-if="feature"
      type="info"
      message="New feature! Lists all profiles that can be associated with recipes."
      @close="feature = false"></alert>

    <div class="page-header">
      <span class="text-truncate">Showing {{items.length}} of {{meta.total}} profiles</span>

      <router-link
        v-if="can(uiPermissions.CUSTOMER_PROFILES_CREATE)"
        to="/customer-profiles/new"
        data-test="btn-new-profile"
        class="btn btn-primary ml-auto">
        New profile
      </router-link>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-customer-profile" class="table table-hover table-outline table-vcenter text-truncate card-table">
              <thead>
                <tr>
                  <th class="w-1">ID</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th class="w-1">Last updated</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.id)});`"></span>
                  </td>
                  <td>
                    <router-link
                      v-if="can(uiPermissions.CUSTOMER_PROFILES_VIEW)"
                      :to="`/customer-profiles/${item.id}`">
                      {{item.name}}
                    </router-link>
                    <span v-else>{{item.name}}</span>
                  </td>
                  <td>{{item.description}}</td>
                  <td>
                    {{item.updated_at}}
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="6">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {customerProfiles} from '@/services';

export default {
  name: 'CustomerProfilesIndex',
  components: {
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      feature: true,
    };
  },
  methods: {
    fetchData(page) {
      return customerProfiles.getByParameters({
        limit: this.limit,
        page,
      });
    },
  },
};
</script>
